.loading-spinner-container {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-spinner-text {
  text-align: center;
  font-size: 30px !important;
  font-weight: 400 !important;
  margin-bottom: 40px !important;
}
