.settings-main-container {
  margin: 15px;
}

.settings-card-content {
  padding-bottom: 0px !important;
}

.settings-card-content-title {
  margin-bottom: 15px !important;
}

.change-password-link {
  text-decoration: none;
}

.async-select-container {
  margin-top: 25px !important;
  margin-bottom: 15px !important;
}

.change-password-button {
  color: white !important;
  background-color: rgb(162, 82, 212) !important;
  margin-top: 15px !important;
}

.logout-button {
  color: white !important;
  margin-top: 15px !important;
}
