.main-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgotten-password-form-container {
  width: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 90%;
  padding-bottom: 5px;
}

.enter-email {
  width: 90%;
  text-align: center;
  font-weight: 400 !important;
  font-size: 26px !important;
}

.forgotten-password-form {
  display: contents;
}

.send-email-link-button {
  width: 90%;
  margin-top: 35px !important;
}

.back-button {
  width: 90%;
  color: white !important;
  margin-top: 35px !important;
}

.forgotten-password-email-textfield {
  width: 90%;
}

.forgotten-password-result-text {
  width: 90%;
}
