.asset-card {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100%;
    max-width: 650px;
    min-width: 260px;
    border-radius: 16px !important;
    box-shadow: 0 8px 16px 0 #bdc9d7 !important;
    border: 4px solid rgb(245, 158, 56);
}

.asset-card-content {
    padding: 0px !important;
}

.asset-header-container {
    padding: 5px;
    border-bottom: 4px solid rgb(245, 158, 56);
}

.asset-header-icon {
    margin-right: 15px;
}

.asset-card-fields-container {
    background-color: rgb(82, 99, 112, 0.05);
    padding: 20px;
    padding-bottom: 10px;
}

.asset-field-container {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.asset-field-name {
    font-weight: 500 !important;
}

.asset-field--show-more-anchor-class .expand {
    position: absolute;
}

/* prevent this ugly color when expand arrow is clicked */
.asset-field--show-more-anchor-class:link,
.asset-field--show-more-anchor-class:visited {
    color: inherit;
}
