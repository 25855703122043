.location-card {
  width: 75vw;
  max-width: 650px;
  min-width: 260px;
  border-radius: 16px;
  box-shadow: 0 8px 16px 0 #bdc9d7;
  border: 4px solid rgb(82, 99, 112, 0.5);
  cursor: pointer;
  margin-top: 25px !important;
  padding: 5px !important;
}

.location-name {
  text-align: center;
}

.view-sublocations-button {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  background-color: rgb(245, 158, 56, 0.1) !important;
}

.raise-request-for-location-button {
  color: rgb(162, 82, 212) !important;
  border-color: rgb(162, 82, 212) !important;
  background-color: rgb(162, 82, 212, 0.1) !important;
}
