.guest-request-container {
    min-width: 375px;
}

.guest-request-toolbar {
    padding: 0 !important;
}

.guest-request-toolbar-title {
    flex-grow: 1;
}

.guest-request-login-button {
    color: white !important;
    margin-right: 24px !important;
}

.guest-request-content {
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.guest-request-avatar {
    background-color: #f59e38 !important;
    height: 75px !important;
    width: 75px !important;
    margin: 8px 8px 50px !important;
}

.guest-request-avatar-icon {
    font-size: 45px !important;
}

.guest-request-textfields {
    width: 100%;
    margin-top: 25px !important;
    /* z-index: -1 !important; */
}

.async-select-container-guest {
    margin-top: 25px !important;
}

.guest-request-field {
    margin-top: 6px;
    margin-bottom: 6px;
}

.guest-request-field-icon {
    font-size: 30px !important;
}

.guest-request-field-name {
    font-size: 20px !important;
    font-weight: 500 !important;
    margin-left: 15px !important;
}

.guest-request-field-value {
    font-size: 20px !important;
    margin-left: 10px !important;
}

.guest-request-details {
    margin-top: 5px !important;
}

.submit-guest-request-button {
    width: 100%;
    margin-bottom: 25px !important;
}

.add-photo-button {
    width: 100%;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
    background-color: hsl(123, 44%, 54%) !important;
}

.add-photo-button-requests {
    margin-bottom: 10px !important;
    width: 50vw;
    max-width: 650px;
    min-width: 260px;
}

@media (max-width: 600px) {
    .guest-request-toolbar {
        justify-content: space-between;
    }
    .guest-request-toolbar-title {
        flex-grow: inherit;
        padding-left: 15px;
    }
    .guest-request-login-button {
        margin-right: 15px !important;
    }
}

/* see https://stackoverflow.com/a/37048027 */
#g-recaptcha-response {
    display: block !important;
    position: absolute;
    margin: -78px 0 0 0 !important;
    width: 302px !important;
    height: 76px !important;
    z-index: -999999;
    opacity: 0;
}
