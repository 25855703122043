.no-results-container {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-results-icon {
  padding-left: 25px;
  padding-right: 25px;
  font-size: 150px !important;
  margin-bottom: 50px !important;
}

.no-results-text {
  padding-left: 25px;
  padding-right: 25px;
  font-size: 40px !important;
  font-weight: 400 !important;
}
