.sublocation-card {
  width: 75vw;
  max-width: 650px;
  min-width: 260px;
  border-radius: 16px;
  box-shadow: 0 8px 16px 0 #bdc9d7;
  border: 4px solid rgb(82, 99, 112, 0.5);
  cursor: pointer;
  margin-top: 25px !important;
  padding: 5px !important;
}

.sublocation-name {
  text-align: center;
}

.raise-request-for-sublocation-button {
  margin-top: 10px !important;
  color: rgb(162, 82, 212) !important;
  border-color: rgb(162, 82, 212) !important;
  background-color: rgb(162, 82, 212, 0.1) !important;
}
