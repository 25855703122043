.error-display-container {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error-display-icon {
  padding-left: 25px;
  padding-right: 25px;
  font-size: 150px !important;
  margin-bottom: 40px !important;
}

.error-display-title {
  padding-left: 25px;
  padding-right: 25px;
  font-size: 40px !important;
  font-weight: 400 !important;
  margin-bottom: 20px !important;
}

.error-display-text {
  padding-left: 25px;
  padding-right: 25px;
  font-size: 20px !important;
  margin-bottom: 70px !important;
}

.retry-button {
  width: 200px;
  font-size: 15px !important;
}
