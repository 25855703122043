.new-request-container {
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.new-request-avatar {
  background-color: #f59e38 !important;
  height: 75px !important;
  width: 75px !important;
  margin: 8px !important;
}

.new-request-avatar-icon {
  font-size: 45px !important;
}

.new-request-title {
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}

.new-request-details {
  width: 100%;
  margin-top: 25px !important;
}

.async-select-container {
  margin-top: 25px !important;
}

.async-select-container-type {
  position: relative;
  z-index: 0;
}

.submit-new-request-button {
  width: 100%;
  margin-top: 10px !important;
  margin-bottom: 25px !important;
}


.lazy-loading-dropdown {
  width: 100%;
  margin-top: 10px !important;
  margin-bottom: 25px !important;
  position: relative;
  z-index:1000000;
}

.lazy-loading-location {
  width: 100%;
  margin-top: 10px !important;
  margin-bottom: 25px !important;
  position: relative;
  z-index:100000;
}

.lazy-loading-sublocation {
  width: 100%;
  margin-top: 10px !important;
  margin-bottom: 25px !important;
  position: relative;
  z-index:10000;
}

.select-container {
	display: flex;
}

.select-sub-container {
	flex: 1;
}

.clear-button-container {
	flex: 0;
	justify-self: flex-end;
}

.site-helper{
  margin-top: 255px !important;
}

