.request-card {
    width: 50vw;
    max-width: 650px;
    min-width: 260px;
    margin-top: 30px;
    border-radius: 16px !important;
    box-shadow: 0 8px 16px 0 #bdc9d7 !important;
}

.request-card-content {
    padding: 0px !important;
}

.request-card-header-container {
    padding: 15px !important;
}

.request-number-container {
    padding: 8px;
    border-radius: 16px;
}

.request-card-header-text {
    color: white;
}

.request-status-container {
    padding: 8px;
    border-radius: 16px;
    width: fit-content !important;
    max-width: 75%;
}

.request-status-icon-container {
    margin-left: 10px !important;
    height: 24px;
}

.request-card-fields-container {
    background-color: rgba(209, 182, 27, 0.05);
    padding: 20px;
    padding-bottom: 0px;
}

.request-field-container {
    margin-bottom: 15px;
}

.request-field-name {
    font-weight: 500 !important;
}

.request-field--show-more-anchor-class .expand {
    position: absolute;
}

/* prevent this ugly color when expand arrow is clicked */
.request-field--show-more-anchor-class:link,
.request-field--show-more-anchor-class:visited {
    color: inherit;
}
