#search-fab {
	position: fixed;
	right: 15px;
	bottom: 15px;
	background-color: #4284f5;  
}

.hidden {
	display: none !important;
}

.not-hidden {
	display: block !important;
}

.clear-button-container {
	flex: 0;
	justify-self: flex-end;
}