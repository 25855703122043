.request-photos-container {
    border: 0px solid transparent;
    margin-bottom: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    max-width: 500px;
}

.request-photos-card-container {
    margin-bottom: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    max-width: 650px;
    min-width: 260px;
    background-color: rgba(209, 182, 27, 0.05);
}

.card-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.no-photos-text {
    color: var(--hot-pink);
    font-size: 20px;
}

.request-photos-header {
    height: 45px;
    width: 100%;
    background-color: var(--dark-blue);
    display: flex;
    align-items: center;
    padding: 6px;
}

.request-photos-display {
    padding: 10px;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
}

.request-photo {
    cursor: pointer;
    object-fit: fill;
    max-width: 100%;
    height: 80px;
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.photo-dialog {
    max-width: 100% !important;
}

.request-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
