.homepage-main-container {
  height: 90vh;
}

.homepage-logo {
  width: 350px;
  margin-bottom: 60px;
}

.homepage-main-text {
  font-weight: 400 !important;
  font-size: 40px !important;
  text-align: center;
  padding: 0px 25px;
}

.homepage-link {
  text-decoration: none;
}

.homepage-buttons-container {
  margin-top: 50px;
  flex-direction: row;
}

.new-request-card {
  width: 250px;
  border: 4px solid rgb(162, 82, 212);
  background-color: rgb(162, 82, 212, 0.1) !important;
  margin-right: 50px;
}

.new-request-icon {
  color: rgb(162, 82, 212);
  font-size: 42px !important;
}

.homepage-link-text {
  margin-top: 15px !important;
  font-size: 1rem !important;
}

.view-requests-card {
  width: 250px;
  border: 4px solid rgb(245, 158, 56);
  background-color: rgb(245, 158, 56, 0.1) !important;
}

.view-requests-icon {
  font-size: 42px !important;
}

@media (max-width: 600px) {
  .homepage-buttons-container {
    flex-direction: column;
  }
  .homepage-buttons-container {
    margin-top: 50px;
  }
  .homepage-main-text {
    padding: 0px 15px;
  }
  .new-request-card {
    margin-bottom: 25px;
    margin-right: 0px;
  }
}

@media (max-width: 375px) {
  .homepage-logo {
    width: 250px;
  }
  .homepage-main-text {
    padding: 0px;
  }
}

@media (max-height: 700px) {
  .homepage-buttons-container {
    margin-top: 50px;
  }
}

@media (max-height: 620px) {
  .homepage-logo {
    margin-bottom: 15px;
  }
  .homepage-buttons-container {
    margin-top: 15px;
  }
}
