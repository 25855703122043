.menu-button {
  margin-right: 12px !important;
}

.title {
  flex-grow: 1;
  text-align: center;
}

.drawer-logo {
  width: 175px;
}

.drawer-settings {
  position: absolute !important;
  bottom: 0;
  padding-top: 0 !important;
  padding-bottom: 0.3em !important;
  width: 100%;
}
