.change-password-main-container {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-password-form-container {
  width: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 90%;
  padding-bottom: 5px;
}

.enter-new-password {
  font-weight: 400 !important;
  font-size: 26px !important;
  margin-bottom: 25px;
}

.change-password-form {
  display: contents;
}

.change-password-message {
  margin-top: 20px !important;
}

.change-password-form-button {
  width: 90%;
  margin-top: 25px !important;
}

.change-password-textfield {
  width: 90%;
}
